import setting from "./setting/index";
import Vuex from "vuex";
import { api } from "../API/Intercepter";
import { handleError } from "vue";
import router from "../router";

export const strict = false;

export default new Vuex.Store({
  modules: {
    setting,
  },
  state: {
    user: null,
    token: null,
    cart: [],
    cartTotal: 0,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
    },
    SET_TOKEN(state, token) {
      state.token = token;
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
    async initialiseStore(state) {
      const token = localStorage.getItem("auth__token");
      const user = localStorage.getItem("auth__user");

      if (token && user) {
        state.user = JSON.parse(user);
        state.token = token;
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
      if (localStorage.getItem("cart")) {
        state.cart = JSON.parse(localStorage.getItem("cart"));
      }
      if (localStorage.getItem("cartTotal")) {
        state.cartTotal = parseFloat(localStorage.getItem("cartTotal"));
      }

      return true;
    },
    addRemoveCart(state, payload) {
      //add or remove item
      payload.toAdd
        ? state.cart.push(payload.product)
        : (state.cart = state.cart.filter(function (obj) {
            return obj.id !== payload.product.id;
          }));

      //calculating the total
      state.cartTotal = state.cart.reduce((accumulator, object) => {
        return parseFloat(accumulator) + parseFloat(object.price);
      }, 0);

      //saving in web storage
      localStorage.setItem("cartTotal", JSON.stringify(state.cartTotal));
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    updateCart(state, payload) {
      //calculating the total
      state.cartTotal = state.cart.reduce((accumulator, object) => {
        return parseFloat(accumulator) + parseFloat(object.price * object.qty);
      }, 0);

      //saving in web storage
      localStorage.setItem("cartTotal", JSON.stringify(state.cartTotal));
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    EMPTY_CART(state) {
      state.cart = [];
      localStorage.removeItem("cart");
      localStorage.removeItem("cartTotal");
    },
  },
  actions: {
    register({ commit }, credentials) {
      let formData = new FormData();
      formData.append("name", credentials.name);
      formData.append("email", credentials.email);
      formData.append("password", credentials.password);
      formData.append("gender", credentials.gender);
      return api.post("register", formData).then((response) => {
        console.log(response.data);
        localStorage.setItem("auth__token", response.data.data.token);
        localStorage.setItem(
          "auth__user",
          JSON.stringify(response.data.data.user)
        );
        commit("SET_USER_DATA", response.data.data.user);
        commit("SET_TOKEN", response.data.data.token);
      });
    },
    login({ commit }, user_info) {
      localStorage.setItem("auth__token", user_info.token);
      localStorage.setItem("auth__user", JSON.stringify(user_info.user));
      commit("SET_USER_DATA", user_info.user);
      commit("SET_TOKEN", user_info.token);
    },
    logout({ commit }) {
      localStorage.removeItem("auth__user");
      localStorage.removeItem("auth__token");

      sessionStorage.removeItem("AuthSessionData");
      sessionStorage.removeItem("AuthSessionExpiry");

      commit("SET_TOKEN", null);
      commit("SET_USER_DATA", null);
      router.push({ name: "auth.login" });
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getToken(state) {
      return state.token;
    },
    getUserProfile(state) {
      return state.user.user_profile;
    },
    getRoles(state) {
      return state.user.roles;
    },
    getCart(state) {
      return state.cart;
    },
    getCartTotal(state) {
      return state.cartTotal;
    },
  },
});
