import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/scss/bootstrap.scss";
import router from "./router";
import "swiper/css/bundle";
import "./assets/css/fontAwesome5Pro.css";
import "bootstrap";
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "./assets/scss/main.scss";
import store from "./store";
import setupInterceptors from "@/Services/setupInterceptors";
import BaseAvatar from "@/components/Base/user/Avatar";
import Swal from "sweetalert2";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Vue3Toasity from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import VueCookies from "vue3-cookies";

import { plugin as formKitPlugin, defaultConfig } from '@formkit/vue'
import { createMultiStepPlugin } from '@formkit/addons'
import '@formkit/themes/genesis'
import '@formkit/addons/css/multistep'
import './registerServiceWorker'


setupInterceptors(store);

let app = createApp(App);
app.use(router).use(store);
app.component("BaseAvatar", BaseAvatar);
app.use(BootstrapVue3);
app.use(VueSweetalert2);
app.use(VueCookies, { expires: '60d'})
app.use(Vue3Toasity, {
  autoClose: 3000,
  limit: 3,
  position: "bottom-center",
  theme: "colored",
  rtl: true,
});
app.use(formKitPlugin, defaultConfig({
  plugins: [createMultiStepPlugin()]
}))

window.Swal = app.config.globalProperties.$swal;
app.mount("#app");
